.SignupPhoto {
  margin-top: 13px;

  .upload {
    position: relative;
    width: 100%;
    height: 127px;
    border-radius: 8px;
    // background: #eaf0f6;
    background: #f5f8fa;
    border: 1px dashed #99acc2;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;

    img {
      max-width: 100%;
      height: 100%;
    }

    input {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 8px;
      opacity: 0;
      cursor: pointer;
    }

    svg {
      width: 60px;
      height: 60px;
      fill: #cbd6e2;
      margin-top: 15px;
      // margin-bottom: 12px;
      pointer-events: none;
    }

    p {
      font-weight: 500;
      font-size: 13px;
      color: #516f90;
    }
  }

  // p {
  //   font-size: 14px;
  //   margin-top: 12px;
  //   margin-bottom: 33px;
  // }
}
