.SignupInput2 {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin-bottom: 32px;
  span {
    font-weight: normal;
    font-size: 14px;
    color: #253342;
  }
  input {
    border: none;
    border-bottom: 2px solid #cbd6e2;
    margin-top: 10px;
    padding: 10px 0;
    font-size: 20px;
    &::placeholder {
      font-weight: normal;
      font-size: 20px;
      color: #cbd6e2;
    }
    &:focus {
      border-bottom: 2px solid #1a73e8;
    }
    &:not(:placeholder-shown) {
      border-bottom: 2px solid #1a73e8;
    }
  }
}
