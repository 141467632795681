.mobileBottom {
  display: none;
}

.category_top_area {
  z-index: 2;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 35px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  svg {
    width: 22px;
    height: 22px;
    cursor: pointer;
  }
  button {
    background: #1a73e8;
    color: #fff;
    box-shadow: 0px 4px 8px rgba(81, 111, 144, 0.12);
    border-radius: 8px;
    width: 153px;
    height: 45px;
    &:disabled {
      background: #ccc;
    }
  }
  .step2_previous {
    display: flex;
    .previous_cat {
      font-size: 16px;
      color: #1a73e8;
      margin-left: 40px;
      cursor: pointer;
    }
  }
}

.choose_category {
  width: 100%;
  height: 100%;
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  overflow: scroll;

  .addNameContent {
    box-shadow: rgba(16, 25, 40, 0.1) 0px 4px 8px 0px;
    border: 1px solid #e7e8e9;
    border-radius: 8px;
    display: block;
    max-width: 770px;
    height: 122px;
    margin: 0 auto;
    margin-top: 20px;
    padding: 16px 24px 32px 24px;
    .SignupInput {
      max-width: 460px;
    }
  }

  .content {
    padding-top: 80px;
    .category_heading {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      h4 {
        margin-top: 20px;
        font-weight: normal;
        font-size: 15px;
        color: #878c93;
        margin-bottom: 12px;
      }
      h5 {
        font-size: 15px;
        color: #000;
        font-weight: normal;
        margin-top: 12px;
      }
      h1 {
        font-weight: 600;
        font-size: 28px;
        color: #253342;
        margin: 0;
      }
    }

    .business_cats_grid {
      // display: block;
      // max-width: 60%;
      // margin: 0 auto;
      max-width: 1120px;
      margin: 0 auto;
      border-radius: 8px;
      padding: 40px 30px;
      &.team {
        max-width: 720px;
        .cats_items {
          grid-template-columns: repeat(4, 1fr);
          .single_cat {
            .img_container {
              height: 75%;
              img {
                height: 100%;
              }
            }
          }
        }
      }
      .cats_items {
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        justify-content: center;
        align-items: center;

        .single_cat {
          box-shadow: rgba(16, 25, 40, 0.1) 0px 4px 8px 0px;
          // width: 110px;
          width: auto;
          height: 110px;
          background: #ffffff;
          border: 1px solid #cbd6e2;
          box-sizing: border-box;
          border-radius: 8px;
          margin-right: 15px;
          margin-bottom: 19px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          position: relative;

          .main_icon {
            width: 45px;
            height: 45px;
            color: #253342;
          }

          .img_container {
            width: 100%;
            height: 100%;
            img {
              width: 100%;
              height: 85%;
              border-radius: 8px 8px 0 0;
            }
          }
          .added_icon {
            fill: #1a73e8;
            background-color: #fff;
            border-radius: 50px;
            position: absolute;
            top: -6px;
            right: -2px;
            width: 20px;
            height: 20px;
          }
          p {
            font-size: 13px;
            text-align: center;
            color: #253342;
            font-weight: 500;
            margin: 0;
          }
          &.active {
            background: #d8f3f4;
            border: 2px solid #1a73e8;
          }
          &.disabled {
            background: rgb(219, 219, 219);
            border: 2px solid rgb(163, 163, 163);
            cursor: not-allowed;
            .main_icon {
              color: #747474;
            }
            p {
              color: #747474;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .B2BSignup {
    h1 {
      font-size: 32px;
    }

    .container {
      padding: 0 20px;
    }

    .logo {
      margin-top: 40px;
      margin-bottom: 20px;
    }
  }

  .mobileBottom {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    height: 70px;
    width: 100%;
    border-top: 1px solid #e7e8e9;
    box-shadow: -1px -4px 9px 0px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    button {
      background: #1a73e8;
      color: #fff;
      box-shadow: 0px 4px 8px rgba(81, 111, 144, 0.12);
      border-radius: 8px;
      width: 80%;
      height: 45px;
      &:disabled {
        background: #ccc;
      }
    }
  }
  .category_top_area {
    height: 65px;
    .previous_cat {
      margin-left: 20px !important;
    }
    button {
      display: none;
    }
  }

  .choose_category {
    .content {
      padding-top: 65px;
    }
    .category_heading {
      align-items: flex-start;
      padding: 16px;
    }
    .addNameContent {
      max-width: 100%;
      box-shadow: none;
      border: none;
      padding: 16px;
      margin-top: 0px;
      .SignupInput {
        max-width: 460px;
        margin: 0 auto;
      }
    }
    .business_cats_grid {
      padding: 16px;
      .cats_items {
        grid-template-columns: repeat(6, 1fr);
        &.team {
          grid-template-columns: repeat(4, 1fr) !important;
        }
      }
    }
  }
}

@media (max-width: 780px) {
  .choose_category {
    .category_heading {
      align-items: flex-start;
      padding: 16px;
    }
    .business_cats_grid {
      .cats_items {
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }
}

@media (max-width: 530px) {
  .choose_category {
    .business_cats_grid {
      .cats_items {
        grid-template-columns: repeat(2, 1fr) !important;
        &.team {
          grid-template-columns: repeat(2, 1fr) !important;
        }
        .single_cat {
          .img_container {
            height: 85%;
            overflow: hidden;
            img {
              height: 85% !important;
            }
          }
          p {
            padding-bottom: 5px;
          }
        }
      }
    }
  }
}

.my-node-enter {
  transform: translateX(200%);
}
.my-node-enter-active {
  transform: translateX(0);

  transition: transform 0.25s;
  transition-delay: 0.2s;
}
.my-node-exit {
  transform: translateX(0);
}
.my-node-exit-active {
  transform: translateX(-200%);
  transition: transform 0.25s;
}
