.infoBg {
  background-color: #f5f7fa;
  margin: 0;
  min-height: 100vh;
  padding-top: 20px;
  .BookingInfo {
    max-width: 592px;
    display: block;
    margin: 0 auto;
    p {
      font-size: 13px;
      line-height: 24px;
      color: #516f90;
      margin: 10px 0;
    }
    .info {
      box-sizing: border-box;
      background: #fff;
      padding: 40px;
      img {
        width: 150px;
      }
      h1 {
        font-size: 24px;
        line-height: 32px;
        margin: 0;
        max-width: 100%;
        color: #222d38;
        font-weight: bold;
        margin: 40px 0 20px 0;
      }
      h5 {
        margin-top: 15px;
        font-weight: 600;
        color: #222d38;
        font-size: 14px;
        &.last {
          margin-top: 60px;
        }
      }
      p {
        font-size: 14px;
        a {
          color: #1a73e8;
        }
      }
      button {
        background-color: #f6303a;
        border-radius: 4px;
        display: inline-block;
        font-weight: 700;
        font-size: 16px;
        line-height: 100%;
        padding: 16px 32px;
        text-align: center;
        color: #ffffff;
        margin: 30px 0 10px 0;
      }
    }
  }
}
