.App {
  height: 100%;
}

p {
  color:#33475B;
}

button {
  background:none;
  border:none;
  cursor: pointer;
}

input,textarea,button,select {
  &:focus {
      outline: none;
  }
}

.radiobox{
  width: 66px;
  background:#EAF0F6;
  border-radius: 500px;
  height:34px;
  float: right;
  transition:.2s;
  cursor: pointer;

  .input{
      position: relative;
      left:6px;
      width: 26px;
      height: 26px;
      background:white;
      border-radius: 50%;
      margin:4px 0;
      transition:.2s;
  }

  &.active{
      background:#00A3AD;
      
      .input{
          left: 35px;
      }
      
  }
}

