.Verification {
  padding-bottom: 100px;
  .signupSubmit {
    height: 80px;
    box-shadow: 0px -4px 12px -3px rgba(0, 0, 0, 0.12);
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;

    .next {
      width: 343px;
      height: 46px;
      background: #1a73e8;
      border-radius: 6px;
      color: #fff;
      font-size: 18px;
    }
    &.backable {
      button {
        width: 229px;
        margin: 0 8px;
        &.back {
          border: 1px solid #cbd6e2;
          box-sizing: border-box;
          border-radius: 6px;
          height: 46px;
          font-size: 18px;
          color: #253342;
        }
      }
    }
  }

  &.mobileActive {
    padding-bottom: 0;
    .SignupHeader {
      display: none;
    }
    .signupSubmit {
      display: none;
    }
  }

  .step3 {
    max-width: 1024px;
    display: block;
    margin: 0 auto;
    margin-top: 60px;
    h1 {
      font-weight: bold;
      font-size: 27px;
      color: #1a73e8;
      margin-bottom: 16px;
    }
    h5 {
      font-weight: normal;
      font-size: 16px;
      color: #516f90;
      margin-bottom: 32px;
    }
    .row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .col {
        // max-width: 330px;
        width: 45%;
        height: 407px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        background: #ffffff;
        border: 1px solid #cbd6e2;
        box-sizing: border-box;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04);
        border-radius: 6px;
        padding: 40px;
        .first {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        svg {
          color: #1a73e8;
          width: 50px;
          height: 50px;
        }
        h2 {
          font-weight: bold;
          font-size: 24px;
          text-align: center;
          color: #253342;
          margin: 16px 0;
        }
        p {
          font-weight: normal;
          font-size: 16px;
          text-align: center;
          color: #516f90;
        }
        button {
          background: #ffffff;
          border: 1px solid #1a73e8;
          box-sizing: border-box;
          border-radius: 6px;
          font-weight: 500;
          font-size: 16px;
          color: #1a73e8;
          padding: 14px 50px;
          transition: 0.3s;
          width: 90%;
          &:hover {
            background: #1a73e8;
            color: #fff;
          }
        }
      }
    }
    .chooseType {
      .codeType {
        width: 100%;
        border: 1px solid #cbd6e2;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04);
        transition: 0.5s;
        // min-height: 80px;
        border-radius: 6px;
        background: #fff;
        margin-bottom: 16px;
        max-height: 80px;
        overflow: hidden;
        .displayCode {
          height: 80px;
          width: calc(100% - 48px);
          background: #ffffff;
          box-sizing: border-box;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 0 24px;
          cursor: pointer;
          svg {
            width: 50px;
            height: 50px;
            color: #1a73e8;
          }

          h3 {
            font-weight: normal;
            font-size: 24px;
            line-height: 29px;
            text-align: center;
            color: #1a73e8;
            padding-right: 45%;
            pointer-events: none;
          }
        }
        &:hover {
          box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
        }
        .desc {
          overflow: hidden;
          padding: 24px;
          opacity: 0;

          .descText {
            margin: 0 0 16px 0;
            font-size: 16px;
            color: #253342;
          }
          .iframe {
            background: #f5f8fa;
            padding: 16px;
            font-weight: 500;
            font-size: 14px;
            color: #1a73e8;
            word-wrap: break-word;
            border: none;
            width: 90% !important;
            resize: none;
            box-sizing: border-box;
            height: 75px;
          }
          .descRow {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 16px;
            position: relative;
            button {
              background: #1a73e8;
              border-radius: 6px;
              color: #fff;
              width: 99px;
              height: 40px;
              font-size: 14px;
            }
            .descInfo {
              display: flex;
              align-items: center;
              color: #516f90;
              font-size: 12px;
              word-wrap: break-word;
              svg {
                color: #1a73e8;
                width: 20px;
                height: 20px;
                margin-right: 7px;
              }
              a {
                text-decoration: none;
                color: #1a73e8;
              }
            }
            .copied {
              position: absolute;
              bottom: 10px;
              left: 130px;
              z-index: 2;
              font-size: 16px;
              color: rgb(89, 255, 0);
              border-radius: 8px;
              font-weight: bold;
            }
          }
        }
        &.active {
          border: 1px solid #1a73e8;
          max-height: 800px;
          .displayCode {
            border-bottom: 1px solid #aab7c5;
          }
          .desc {
            opacity: 1;
          }
        }
        &:nth-child(2) {
          .displayCode {
            svg {
              color: #464342;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .Verification .step3 {
    padding: 0 16px;
    .row {
      .col {
        width: 48%;
      }
    }
    .iframe {
      height: 100px !important;
    }
  }
}

@media (max-width: 768px) {
  .Verification .step3 {
    .row {
      flex-direction: column;
      .col {
        width: 330px !important;
        margin-bottom: 16px;
      }
    }
    .iframe {
      height: 120px !important;
    }
  }
}

@media (max-width: 476px) {
  .displayCode {
    h3 {
      padding-right: 25% !important;
    }
  }
  .iframe {
    height: 155px !important;
  }
}

@media (max-width: 350px) {
  .Verification {
    .signupSubmit {
      .next {
        width: 90%;
      }
    }
  }
}
