.stepContainer {
  display: block;
  margin: 0 auto;
  max-width: 1024px;
  .mobilePreview {
    display: none;
  }
  .step1 {
    // max-width: 820px;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    margin-top: 50px;
    // align-items: center;

    .left {
      // max-width: 360px;
      width: 40%;

      h1 {
        font-weight: bold;
        font-size: 27px;
        color: #1a73e8;
        margin-top: 0;
        margin-bottom: 32px;
      }
      h5 {
        font-size: 16px;
        color: #253342;
        margin-bottom: 8px;
        font-weight: 500;
      }
      span {
        font-size: 16px;
        color: #516f90;
      }

      .SignupPhoto {
        margin-bottom: 30px;
      }

      .colors {
        display: flex;
        align-items: center;

        .singleColor {
          width: 40px;
          height: 40px;
          border-radius: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 10px;
          cursor: pointer;

          svg {
            color: #fff;
            width: 22px;
            height: 22px;
            font-weight: bold;
          }
        }
      }
    }
    .right {
      // max-width: 380px;
      width: 40%;
      height: 600px;
      filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.12));
      background-color: #fff;
      margin-bottom: 100px;
      .preview {
        width: 100%;
        height: 280px;
        padding: 40px;
        box-sizing: border-box;
        img {
          width: 170px;
          height: 30px;
        }
        h1 {
          color: #fff;
          font-weight: bold;
          font-size: 30px;
          line-height: 36px;
          margin-top: 28px;
          margin-bottom: 16px;
        }
        h3 {
          color: #fff;
          font-weight: normal;
        }
      }
      .personellPreview {
        position: absolute;
        top: 210px;
        left: 16px;
        width: calc(100% - 32px);
        // height: 190px;
        box-sizing: border-box;
        font-weight: bold;
        background-color: #fff;
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
        border-radius: 12px;
        padding: 24px 0 0 24px;
        h2 {
          font-size: 16px;
          color: #253342;
          margin: 0 0 12px 0;
        }
        .personell {
          display: flex;
          align-items: center;

          .singlePersonell {
            width: 56px;
            height: 56px;
            background: #eaf0f6;
            border: 2px solid #ffffff;
            box-sizing: border-box;
            border-radius: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 40px;
            svg {
              width: 24px;
              height: 24px;
              color: #99acc2;
            }
            &.abs {
              position: absolute;
              left: 50px;
            }
          }
          p {
            font-size: 14px;
            color: #516f90;
            font-weight: normal;
          }
          .hours {
            display: flex;
            align-items: center;
            svg {
              width: 18px;
              height: 18px;
              // color: #1a73e8;
              margin-right: 7px;
            }
            span {
              font-weight: 600;
              font-size: 14px;
              color: #253342;
            }
          }
        }
        button {
          width: 202px;
          height: 45px;
          border-radius: 500px;
          margin: 16px 0 24px 0;
          color: #ffffff;
          font-weight: 500;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          svg {
            width: 17px;
            height: 17px;
            margin-left: 15px;
          }
        }
      }
      .expand {
        position: absolute;
        top: 420px;
        left: 16px;
        width: calc(100% - 32px);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 24px;
        background: #ffffff;
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
        border-radius: 12px;
        height: 65px;
        cursor: pointer;
        box-sizing: border-box;
        h1 {
          font-size: 14px;
          color: #253342;
          font-weight: bold;
        }
      }
      .bottomWidgetArea {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 42px;
        justify-content: center;
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #99acc2;
        background: #ffffff;
        box-shadow: 0px -1px 12px rgba(0, 0, 0, 0.06);
        img {
          margin-left: 5px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .stepContainer {
    .mobilePreview {
      background: #f5f8fa;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 16px;
      height: 49px;
      cursor: pointer;
      p {
        font-weight: 500;
        font-size: 14px;
        color: #1a73e8;
        margin: 0;
      }
      svg {
        color: #1a73e8;
        width: 18px;
        height: 18px;
      }
    }
    .step1 {
      .left {
        width: 100%;
        padding: 0 16px;
      }
      .right {
        display: none;
        width: 100%;
      }
    }
    &.active {
      .mobilePreview {
        justify-content: flex-start;
        background-color: #fff;
        p {
          margin-left: 4px;
        }
      }
      .step1 {
        margin-top: 0;
        .left {
          display: none;
        }
        .right {
          display: block;
          height: calc(100vh - 49px);
          margin-bottom: 0;
        }
      }
    }
  }
}
