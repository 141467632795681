.WholeLogin {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  text-align: center;

  h1 {
    font-size: 40px;
    margin-top: 40px;
    margin-bottom: 36px;
  }
  .LoginWith:nth-of-type(2) {
    margin-bottom: 22px;
  }

  .or {
    display: block;
    width: 50px;
    position: relative;
    text-transform: uppercase;
    font-size: 12px;
    color: #99acc2;
    margin: 0 auto;
    background: white;
    margin-bottom: 13px;

    &:after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      height: 1px;
      background: #99acc2;
      width: 350px;
      transform: translate(-50%, -50%);
      z-index: -1;
    }
  }

  .noAccount {
    p {
      margin: 0 0 6px 0;
      font-size: 16px;
      color: #878c93;
    }
    a,
    .signup {
      color: #1a73e8;
      text-decoration: none;
      font-size: 16px;
      cursor: pointer;
    }
  }
}
