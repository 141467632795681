.ServicesCustomization {
  max-width: 1024px;
  display: block;
  margin: 0 auto;
  margin-top: 60px;
  form {
    h1 {
      font-weight: bold;
      font-size: 27px;
      color: #1a73e8;
      margin-bottom: 30px;
    }
    .inputs {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }
  .categories {
    margin-top: 40px;
    h2 {
      font-weight: 500;
      font-size: 16px;
      color: #253342;
    }
    .business_cats_grid {
      margin-top: 45px;
      .cats_items {
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        justify-content: center;
        align-items: center;
        .single_cat {
          // width: 110px;
          width: auto;
          height: 110px;
          background: #ffffff;
          border: 1px solid #cbd6e2;
          box-sizing: border-box;
          border-radius: 8px;
          margin-right: 15px;
          margin-bottom: 19px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          position: relative;

          .main_icon {
            width: 45px;
            height: 45px;
            color: #253342;
          }

          .img_container {
            width: 100%;
            height: 100%;
            img {
              width: 100%;
              height: 100%;
              border-radius: 8px 8px 0 0;
            }
          }
          .added_icon {
            fill: #1a73e8;
            position: absolute;
            top: -6px;
            right: -2px;
            width: 20px;
            height: 20px;
            background-color: #fff;
            border-radius: 50px;
          }
          p {
            font-size: 13px;
            text-align: center;
            color: #253342;
            font-weight: 500;
            margin: 5px 0;
          }
          &.active {
            background: #d8f3f4;
            border: 2px solid #1a73e8;
          }
          &.disabled {
            background: rgb(219, 219, 219);
            border: 2px solid rgb(163, 163, 163);
            cursor: not-allowed;
            .main_icon {
              color: #747474;
            }
            p {
              color: #747474;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .ServicesCustomization {
    padding: 0 16px;
  }
  .business_cats_grid {
    .cats_items {
      grid-template-columns: repeat(6, 1fr) !important;
    }
  }
}

@media (max-width: 780px) {
  .business_cats_grid {
    .cats_items {
      grid-template-columns: repeat(4, 1fr) !important;
    }
  }
  .SignupInput2 {
    width: 95% !important;
  }
}
@media (max-width: 530px) {
  .inputs {
    grid-template-columns: repeat(1, 1fr) !important;
  }
  .business_cats_grid {
    .cats_items {
      grid-template-columns: repeat(2, 1fr) !important;
      .single_cat {
        .img_container {
          height: 85%;
          overflow: hidden;
          img {
            height: 85% !important;
          }
        }
        p {
          padding-bottom: 5px;
        }
      }
    }
  }
}
