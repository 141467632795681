.SignupForm {
  margin-top: 24px;
  .row {
    margin-top: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .SignupInput {
      width: 260px;
      p {
        position: absolute;
        left: 15px;
        top: 36px;
        font-size: 15px;
      }
      input[type="tel"] {
        padding-left: 50px;
      }
    }
  }
  .terms {
    margin-bottom: 10px;
    p {
      cursor: default;
      color: #101928;
      text-overflow: inherit;
      margin-left: 39px;
    }
    a {
      text-decoration: none;
      color: #1a73e8;
    }
    .checkbox {
      position: absolute;
      cursor: pointer;
      width: 24px !important;
      height: 24px !important;
      background-color: #fff;
      border: 1px solid #ccc;
      box-shadow: rgba(16, 25, 40, 0.06) 0px 4px 6px 0px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      margin-right: 15px;
      &.active {
        border: none;
        background-color: #1a73e8;
      }
      svg {
        color: #fff;
      }
    }
  }
  button {
    margin: 12px 0;
    width: 100%;
    background-color: #1a73e8;
    height: 46px;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    &:disabled {
      background-color: #ccc;
    }
  }
  .bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      color: #878c93;
      font-size: 16px;
    }
    a {
      text-decoration: none;
      color: #1a73e8;
    }
  }
}

@media (max-width: 768px) {
  .SignupForm {
    .row {
      flex-direction: column;
      .SignupInput {
        width: 100%;
      }
    }
  }
}
