.Review {
  background: #f5f8fa;
  height: 100vh;
  .header {
    width: 100%;
    height: 60px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .rate {
    display: block;
    max-width: 456px;
    margin: 0 auto;
    background: #fff;
    margin-top: 50px;
    padding: 41px 33px 28px 33px;
    box-shadow: 0px 4px 12px rgba(81, 111, 144, 0.25);
    border-radius: 8px;
    h1 {
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      text-align: center;
      color: #253342;
      margin: 0 0 30px 0;
    }
    h4 {
      font-weight: 600;
      font-size: 14px;
      color: #253342;
      margin: 0 0 12px 0;
    }
    .stars {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 40px;
      height: 50px;
      .star {
        position: relative;
        cursor: pointer;
        svg {
          width: 50px;
          height: 50px;
          color: #253342;
          &.fill {
            display: none;
          }
        }
        p {
          position: absolute;
          top: 33%;
          left: 50%;
          transform: translateX(-50%);
          font-weight: 600;
          font-size: 12px;
          color: #253342;
        }
        &.active {
          svg {
            // fill: #ffc830;
            display: none;
            &.fill {
              display: block !important;
              color: #ffc830;
            }
          }
        }
      }
    }
    textarea {
      width: 100%;
      height: 139px;
      background: #ffffff;
      border: 1px solid #cbd6e2;
      box-sizing: border-box;
      border-radius: 8px;
      resize: none;
      padding: 12px 15px;
      font-size: 16px;
      line-height: 26px;
      &::placeholder {
        font-size: 16px;
        line-height: 26px;
        color: #7c98b6;
      }
      &:focus {
        border: 1px solid #1a73e8;
      }
      &:not(:placeholder-shown) {
        border: 1px solid #1a73e8;
      }
    }
    button {
      width: 100%;
      height: 48px;
      background: #1a73e8;
      border-radius: 8px;
      font-weight: 500;
      font-size: 16px;
      color: #ffffff;
      margin-top: 28px;
      &:disabled {
        background: #ccc;
      }
    }
  }
  .bottom {
    position: absolute;
    bottom: 33px;
    // left: 50%;
    // transform: translateX(-50%);
    width: 100%;
    text-align: center;
    font-size: 12px;
    color: #7c98b6;
  }
}

@media (max-width: 540px) {
  .Review {
    background-color: #fff;
    .rate {
      margin-top: 0px;
      box-shadow: none;
      border-radius: 0px;
    }
  }
}
