.SignupHeader {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 24px;
  height: 70px;

  .steps {
    display: flex;
    align-items: center;
    margin-left: 32px;

    p {
      font-weight: 600;
      font-size: 14px;
      color: #1a73e8;
    }

    .dots {
      display: flex;
      align-items: center;
      margin-left: 16px;

      .dot {
        width: 8px;
        height: 8px;
        border-radius: 50px;
        background: #cbd6e2;
        margin-right: 6px;

        &.active {
          background: #1a73e8;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .SignupHeader {
    margin-left: 16px;
    justify-content: space-between;
    img {
      width: 80%;
    }
    .steps {
      margin-left: 0;
      padding-right: 10px;
    }
  }
}
