.SignupInput {
  position: relative;

  .form-error {
    color: #f64e60;
  }

  p.baseUrl {
    position: absolute;
    top: 33%;
    left: 0;
    padding-left: 13px;
    padding-right: 11px;
    background: #f5f8fa;
    color: #516f90;
    height: 40px;
    border-radius: 8px 0px 0px 8px;
    border: 1px solid #cbd6e2;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.baseUrl {
    input {
      padding-left: 135px !important;
    }
    input:not(:placeholder-shown) ~ .baseUrl {
      border: 1px solid #00a3ad !important;
      border-right-color: #cbd6e2 !important;
    }
  }

  &.svg {
    input {
      padding-left: 35px;
    }
  }

  label {
    color: #33475b;
    font-weight: 700;
    font-size: 14px;
    display: block;
    text-align: left !important;
  }
  p {
    font-size: 14px;
    margin: 4px 0;
  }
  input,
  textarea {
    display: block;
    width: 100%;
    height: 46px;
    border-radius: 4px;
    border: 1px solid #cbd6e2;
    margin-top: 6px;
    margin-bottom: 16px;
    padding: 0 15px;
    font-size: 15px;
    box-sizing: border-box;

    &:focus {
      // border: 1px solid #00a3ad !important;
      border: 1px solid #1a73e8 !important;
    }

    &::placeholder {
      font-size: 16px;
      line-height: 22px;
      color: #99acc2;
    }

    &.error {
      margin-bottom: 0px;
    }
  }

  input:valid,
  textarea:not(:placeholder-shown) {
    // border: 1px solid #00a3ad;
    border: 1px solid #1a73e8 !important;
  }

  input:disabled {
    background: #eaf0f6;
  }

  textarea {
    height: 123px;
    padding: 10px 10px;
    resize: vertical;
  }

  .createAndShow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    p {
      color: #00a3ad;
      font-weight: 600;
      font-size: 12px;
      margin: 0;

      &.active {
        text-decoration: underline;
      }
    }
  }

  input:focus + svg,
  input:valid + svg {
    // fill: #00a3ad;
  }

  svg {
    position: absolute;
    width: 17.5px;
    height: 17.5px;
    left: 12px;
    top: 39px;
    fill: #cbd6e2;
  }

  button {
    margin: 0;
    padding: 8px 0;
    color: #00a3ad;
    font-weight: bold;
    font-size: 12px;
  }
}
