.ReviewProgress {
  width: 100%;
  height: 2px;
  background: #ccc;
  display: flex;
  border-radius: 5px;
  position: fixed;
  top: 80px;
  z-index: 2;

  .reviewFilled {
    background-color: #1a73e8;
    border-radius: 5px;
    height: 2px;
  }
}
@media (max-width: 1024px) {
  .ReviewProgress {
    top: 65px;
  }
}
