.Login {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f2f2f7;
  height: 100vh;
  padding-top: 24px;
  box-sizing: border-box;
  &.signup {
    .loginContent {
      width: 630px;
    }
    .topArea {
      max-width: 630px;
    }
  }
  .topArea {
    position: relative;
    width: 100%;
    display: flex;
    max-width: 448px;
    height: 30px;
    .back {
      cursor: pointer;
    }
    svg {
      width: 30px;
      height: 30px;
    }
    a {
      position: absolute;
      left: 0px;
      right: 0px;
      margin: 0px auto;
      height: 14px;
      width: 156px;
    }
  }
  .loginContent {
    background: #fff;
    padding: 24px 40px;
    border-radius: 8px;
    box-shadow: rgba(16, 25, 40, 0.08) 0px 4px 8px 0px;
    margin-top: 24px;
    width: 448px;
    box-sizing: border-box;
    h1 {
      margin: 0 0 12px 0;
      font-size: 24px;
      font-weight: bold;
    }
    .description {
      color: #878c93;
    }
    &.professionals {
      text-align: center;
      padding: 24px 40px;
      p {
        margin: 0 0 6px 0;
        font-size: 15px;
        color: #878c93;
        width: 100%;
      }
      a {
        text-align: center;
        text-decoration: none;
        color: #1a73e8;
      }
    }
  }
  .allRights {
    margin-top: 24px;
    color: #878c93;
    font-size: 15px;
  }
}

@media (max-width: 768px) {
  .Login {
    background: #fff;
    .topArea {
      max-width: 100%;
      .back {
        margin-left: 20px;
      }
    }
    .loginContent {
      box-shadow: none;
      border-bottom: 1px solid rgb(231, 232, 233);
      padding: 24px 16px;
      padding-top: 10px;
      box-sizing: border-box;
      width: 100% !important;
      margin-top: 10px;
      h1 {
        text-align: center;
      }
      &.professionals {
        border-bottom: none;
      }
      .WholeLogin {
        width: 100%;
      }
    }
    .allRights {
      margin-top: 10px;
      padding-bottom: 20px;
    }
  }
}
